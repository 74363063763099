body {
  font-family: Arial, san-serif;
  font-size: 14px;
  color: #000;
}

a.ll_btn_in {
  font-weight: normal;
  color: #fff;
}

a.ll_btn_in:hover {
  color: #b73d40;
}

a.ll_btn_in:active {
  color: #fff;
}

.ll-layout-auth .card_form {
  border-radius: 4px;
}

.ll-main-form {
  margin: 0 auto;
  max-width: 425px;
  text-align: center;
}

.ll-main-wrap {
  min-height: 90vh;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ll-main-form__or {
  margin-bottom: 20px;
}

.ll-main-form__top {
  padding: 40px;
  background: #f9f9f9;
}

.ll-main-form__bottom {
  padding: 25px 40px 40px;
}

.ll-form_control .form_input {
  border: 1px solid #000000;
  border-radius: 4px;
  background: #f9f9f9;
  font-size: 14px;
}

.ll-edit-profile .form_input {
  font-size: 14px;
}

.ll-form_control .form_input.invalid {
  border: 1px solid #eb494d;
}

.ll-main-form__btn {
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  display: inline-flex;
  padding: 0 55px;
  border: none;
}

.ll-main-form__title {
  font-size: 28px;
  font-weight: bold;
  margin-top: 70px;
  margin-bottom: 50px;
  line-height: 33px;
}
.ll-main-form__title span {
  color: #b73d40;
}

.ll-main-form__title_secondary {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 50px;
}

.ll-main-form .btn_facebook,
.ll-main-form .btn_google {
  background: linear-gradient(180deg, #4a93ff 0%, #1d60c5 100%);
  font-size: 16px;
  font-weight: bold;
  height: 50px;
}

.ll-main-form .form_input {
  font-size: 14px;
}

.ll-form_control .validation_label {
  top: 55px;
  color: #eb494d;
}

.ll-main-form__btn_register {
  margin: 0 auto 30px;
  display: inline-flex;
  width: auto;
}

.ll-main-form__btn_secondary {
  background: linear-gradient(180deg, #c1c1c1 0%, #808080 100%);
  width: auto;
}
.ll-main-form__btn_secondary:hover {
  background: linear-gradient(180deg, #808080 0%, #c1c1c1 100%);
}

.ll-main-form__text_agree {
  color: #999999;
}

.ll-main-form__text_agree a {
  text-decoration: underline;
  color: #999999;
}

.ll-nav-menu__item {
  fill: #dadada;
  color: #000000;
  height: 40px;
  padding: 0 15px;
  border-radius: 4px;
  margin-left: 3px;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: solid 0ll-5px transparent;
  position: relative;
  cursor: pointer;
  line-height: 1;
}
.ll-nav-menu__item {
  border: 1px solid #fff;
}
.ll-nav-menu__item:hover {
  border: 1px solid #e75b5e;
  background: #f8e3e3;
  color: #eb494d;
  fill: #eb494d;
}

.ll-nav-menu__item:active {
  border: 1px solid #e75b5e;
  background: #f8e3e3;
  color: #eb494d;
  fill: #eb494d;
}
.ll-nav-menu__item span.active,
.ll-nav-menu__item.active span.no-active {
  display: none;
}

.ll-nav-menu__item.active span.active,
.ll-nav-menu__item span.ll-no-active {
  display: flex;
}

.ll-nav-menu__item.active {
  color: #eb494d;
  fill: #eb494d;
  display: flex;
  align-items: center;
  border: 1px solid #e75b5e;
  background: #f8e3e3;
}
/* 
.ll-nav-menu__item.active svg {
  fill: #fff;
} */

.ll-nav-menu__item:hover svg {
  /* fill: #eb494d; */
}

.ll-nav-menu__item:hover svg path {
  fill: #eb494d;
}

.ll-nav-menu__item:hover svg circle {
  stroke: #eb494d;
}

#svgexeption .ll-nav-menu__item:hover svg path {
  fill: #f8e3e3;
  stroke: #eb494d;
}

/* .ll-nav-menu__item:hover svg path {
  stroke: #eb494d;
} */

.ll-nav-menu__item.new-notifications:before {
  content: '';
  display: block;
  position: absolute;
  left: auto;
  right: 6px;
  top: 3px;
  border-radius: 8px;
  background: #ec004a;
  width: 11px;
  height: 11px;
}
.ll-card_wrap {
  position: absolute;
  bottom: 11px;
  left: 15px;
}
.card_name {
  font-weight: 700;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
}
.ll-btns-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.btn_people_card {
  border-radius: 0;
  height: 46px;
  font-weight: 400;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  justify-content: flex-start;
  padding-left: 20px;
  background: linear-gradient(180deg, #f26265 0%, #b73d40 100%);
}
.btn_people_card:hover {
  background: linear-gradient(180deg, #b73d40 0%, #f26265 100%);
}
.ll-card_content {
  padding-bottom: 0;
}
.ll-card {
  background: #fff;
  border: solid 0.5px #d9e6ef;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 20px;
  max-width: 316px;
  min-width: 316px;
  margin-left: 21px;
  cursor: pointer;
  text-decoration: none;
}
.ll-notifications {
  background-color: #f9f9f9 !important;
}
.ll-notifications .nav-menu__text {
  color: #000;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}
.ll_img_avatar {
  border-radius: 4px !important;
  border-right: 1px solid #dedede !important;
}
.notifications__list .ll_dialog {
  padding: 0 9px 0 0;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 0px;
}
.list_container .ll_dialog {
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 0px;
}
.ll_dialog.new .new_message {
  border-radius: 4px !important;
}
.list_container .ll_dialog.new {
  background-color: #fff;
  border: 1px solid #dedede !important;
  overflow: hidden;
}
.list_container .ll_dialog.new .ll_img_avatar {
  border-radius: 4px 0 0 4px !important;
}
.notifications__list .ll_dialog.new {
  background-color: #fff;
  border: 1px solid #dedede !important;
  overflow: hidden;
}
.ll_dialog_photo {
  width: 118px !important;
  height: 118px !important;
  margin: 0 !important;
}
.ll_dialog .dialog_content {
  border-bottom: none;
}
.ll_dialog .dialog_title {
  color: #000;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}
.ll-messages-tabs {
  display: flex;
  margin-bottom: 23px;
  justify-content: space-between;
  align-items: flex-end;
}
.ll-messages-tabs .messages-tab {
  color: #000;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.ll-messages-tabs .messages-tab.active {
  color: #eb494d;
  background: #f8e3e3;
  border-radius: 4px;
  border: 1px solid #e75b5e;
}
.ll-card a.card_link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.ll-popup_body__profile {
  background: #f9f9f9;
  border-radius: 4px;
}
.ll-popup_body__profile .popup_head {
  background: #f9f9f9;
  min-height: 74px;
  height: auto;
}
.ll-popup_body__profile .profile_location {
  display: inline;
  font-size: 16px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}
.ll-popup_body__profile .profile_info {
  margin-top: 10px;
}
.ll-popup_body__profile .profile_title {
  display: inline;
  font-size: 16px;
  font-weight: 800;
  font-family: Arial, Helvetica, sans-serif;
}
.ll-popup_body__profile .popup_content {
  border-top: solid 0.5px #f9f9f9;
  border-bottom: solid 0.5px #f9f9f9;
  background: #fff !important;
}
.ll-popup_body__profile .popup_footer {
  background: #f9f9f9;
}
.ll-popup_body__profile .btn_favorites {
  background: linear-gradient(180deg, #f26265 0%, #b73d40 100%);
  font-size: 14px;
  font-weight: 400;
}
.ll-popup_body__profile .btn_favorites:hover {
  background: linear-gradient(180deg, #f26265 0%, #d6181b 100%) !important;
}
.ll-popup_body__profile .btn_favorites.active:hover .ll-undo {
  color: #fff !important;
}
.ll-undo {
  color: #f7b500;
  font-weight: 700 !important;
}
.ll-popup_body__profile .btn_favorites.active {
  color: #fff !important;
  font-weight: 400 !important;
}

.ll-popup_body__profile .btn_primary {
  background: linear-gradient(180deg, #f26265 0%, #b73d40 100%);
  color: white;
}
.ll-popup_body__profile .btn_chat {
  background: linear-gradient(180deg, #f26265 0%, #d6181b 100%);
  color: white;
  font-size: 14px;
  font-weight: 400;
}
.ll-popup_body__profile .user_description {
  background: none;
  padding: 0;
}
.ll-popup_body__profile .about_mobile {
  background: none;
  padding: 0;
  margin-bottom: 10px;
}
.ll-popup_body__profile .user_description:before {
  display: none;
}
.ll-popup_body__profile .user_description::after {
  display: none;
}
.ll-popup_body__profile .btn_form {
  font-size: 14px;
  font-weight: 400;
}
.ll-popup_body__profile .btn_chat:hover {
  background: linear-gradient(180deg, #f26265 0%, #b73d40 100%) !important;
}
.ll-popup_body__profile .btn_primary:hover {
  background: linear-gradient(180deg, #f26265 0%, #d6181b 100%) !important;
}
.ll-popup_body__profile .profile_row__title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.ll-messages-area__head {
  height: 142px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 11px 20px 11px 11px;
  background: #f9f9f9;
  border: 1px solid #dedede;
}
.ll-messages-area__head .btn_close svg {
  height: 18px;
  fill: #999999;
}
.ll-popup_content__chat {
  text-align: left;
  background: #ffffff;
}

.ll-popup_content__chat.scroll {
  border: none;
}
.message_text.ll-from {
  background: #e5e5ea;
  color: #000000;
  border-radius: 8px;
}

.message_text.ll-to {
  background: #999999;
  color: #fff;
  border-radius: 8px;
}

.messages_list.ll-from {
  margin-right: auto;
}

.messages_list.ll-to {
  margin-left: auto;
}

.ll-footer .footer__links {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 52px auto 40px;
  flex-wrap: wrap;
}
.ll-footer .footer__link:hover {
  text-decoration: underline;
}

.ll-footer .footer__link {
  font-size: 14px;
  color: #999999;
  text-decoration: none;
  transition: color 0.3s ease;
  padding-right: 15px;
  margin-bottom: 5px;
}

.ll-footer .footer__link:last-child {
  padding-left: 0;
  margin-bottom: 0;
}

.ll-footer .footer__link:hover {
  color: #999999;
}

.ll-footer .footer__link:active {
  color: #999999;
}

.wrap_btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.wrap_btns .ll-main-form__btn_register {
  margin: 10px;
}
.ll-track {
  width: 100%;
  height: 7px;
  background-color: #dedede;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 4px;
}
.ll-process {
  background-color: #4a93ff;
  height: 7px;
}
.select_flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.pref_date {
  width: 60px;
  border-radius: 4px;
  border: 1px solid #000000;
  background: #f9f9f9;
  height: 42px;
  margin: 0 10px;
}

.ll-item_select {
  position: relative;
}
.ll-item_select .form_input {
  background: #f9f9f9;
  border: 1px solid #000;
  border-radius: 4px;
  color: #000;
}
.ll-item_select .form_input:hover {
  background: #f9f9f9 !important;
}
.ll-item_select .form_input::placeholder {
  color: #000;
}

.ll-item_select .form_input:hover {
  background: #f9f9f9;
}
.ll-item_select .form_input.invalid {
  border: 1px solid #ec0000;
}
.ll-item_select .select-menu-single {
  background: #f9f9f9;
  color: #000;
  box-shadow: 0 0 4px 0 #000;
  border: 1px solid #000;
}
.ll-item_select .select-menu-single__item:hover {
  background: #f9f9f9;
  color: #000;
}
.ll-loader-wrap {
  margin: 0 auto;
  width: 80%;
  margin-bottom: 90px;
}
.ll-loader-br {
  border-radius: 10px;
  overflow: hidden;
}

.ll-my-profile-popup_body .profile_location {
  font-size: 16px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}

.ll-my-profile-popup_body .btn_close {
  margin-top: 5px;
}

.ll-my-profile-popup_body .footer__link {
  font-size: 14px;
}
.ll-my-profile-popup_body .profile_description {
  padding: 15px 0;
}
.ll-my-profile-popup_body .profile_info {
  margin-top: 10px;
}
.ll-my-profile-popup_body .profile_title {
  font-size: 16px;
  font-weight: 800;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  line-height: 19px;
}
.ll-my-profile-popup_body .profile_info {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 0;
  padding-bottom: 0;
}

.ll-my-profile-popup_body .row-action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  fill: #eb494d;
  transition: fill, color 0.3s ease;
}
.ll-my-profile-popup_body .row-action:hover {
  fill: #b73d40;
  transition: fill, color 0.3s ease;
}
.ll-my-profile-popup_body .row-action:hover span {
  color: #b73d40;
}

.ll-my-profile-popup_body .row-action:active {
  fill: #b73d40;
  transition: fill, color 0.3s ease;
}
.ll-my-profile-popup_body .row-action:active span {
  color: #b73d40;
}
.ll-my-profile-popup_body .row-action span {
  display: block;
  margin-left: 10px;
  color: #eb494d;
  font-weight: 600;
}
.ll-my-profile-popup_body .collection_row:hover .collection_col_arrow {
  stroke: #b73d40;
}

.ll-my-profile-popup_body .collection_row:active .collection_col_arrow {
  stroke: #b73d40;
}

.ll-my-profile-popup_body .collection_row:hover .collection_col_left {
  color: #b73d40;
  fill: #b73d40;
}

.ll-my-profile-popup_body .collection_row:active .collection_col_left {
  color: #b73d40;
  fill: #b73d40;
}
.ll-my-profile-popup_body .collection_col_left {
  color: #eb494d;
  fill: #eb494d;
}

.ll-my-profile-popup_body .collection_col_arrow {
  stroke: #eb494d;
}
.ll-my-profile-popup_body .popup_head {
  background: #f9f9f9;
  height: auto;
}
.ll-my-profile-popup_body .popup_content {
  border-top: solid 0.5px #f9f9f9;
  border-bottom: solid 0.5px #f9f9f9;
  background: #f9f9f9;
}
.ll-my-profile-popup_body .popup_footer {
  background: #f9f9f9;
}
.ll-my-profile-popup_body .load-photo__btn {
  background: #eb494d;
}
.ll-my-profile-popup_body .load-photo__btn:hover {
  background: #b73d40;
}

.ll-my-profile-popup_body .user_description:before,
.ll-my-profile-popup_body .user_description:after {
  display: none;
}

.ll-my-profile-popup_body .user_description:before {
  display: none;
}
.ll-my-profile-popup_body .profile_row__title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}
.ll-my-profile-popup_body .btn_favorites {
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}
.ll-my-profile-popup_body .btn_primary {
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}

.ll-edit-profile {
  background: #f9f9f9;
  border-radius: 4px;
}
.ll-edit-profile .popup_head {
  background: #f9f9f9;
}
.ll-edit-profile .popup_content {
  border-top: solid 0.5px #f9f9f9;
  border-bottom: solid 0.5px #f9f9f9;
  background: #f9f9f9;
}
.ll-edit-profile .popup_footer {
  background: #f9f9f9;
}
.ll-edit-profile .collection_col_left {
  color: #fff;
  fill: #fff;
}

.ll-edit-profile .load-photo__btn {
  background: #eb494d;
}
.ll-edit-profile .load-photo__btn:hover {
  background: #b73d40;
}

.ll-edit-profile .btn_form {
  background: #eb494d;
  color: white;
}
.ll-edit-profile .btn_form:hover {
  background: #b73d40 !important;
}

.ll-edit-profile .btn_form:disabled {
  background: #b86b6c !important;
}

.ll-popup_body__profile .activity_item {
  background: #f8e3e3;
  border: 1px solid #eb494d;
  border-radius: 4px;
  color: #eb494d;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.ll-edit-profile .activities_item {
  background: #f8e3e3;
  border: 1px solid #eb494d;
  border-radius: 4px;
  color: #eb494d;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}
.ll-edit-profile .activities_item:hover {
  background: #eb494d;
  border: 1px solid #eb494d;
  color: white;
}
.ll-edit-profile .activities_item.active {
  background: #eb494d;
  border: 1px solid #eb494d;
  color: #fff;
}
.ll-form_input_area {
  background: #f9f9f9;
  border: 1px solid #000;
  border-radius: 4px;
  color: #000;
  font-size: 14px;
}
.ll-form_input_area:hover {
  background: #f9f9f9;
}
.ll-form_input_area::placeholder {
  color: rgb(116, 114, 114);
  font-size: 14px;
}

.ll-lang_select .form_input {
  background: #f9f9f9;
  border: 1px solid #000;
  border-radius: 4px;
  color: #000;
}
.ll-lang_select .form_input:hover {
  background: #f9f9f9 !important;
}
.ll-lang_select .form_input::placeholder {
  color: #000;
}
.ll-lang_select .form_input:hover {
  background: #f9f9f9;
}
.ll-lang_select .form_input.invalid {
  border: 1px solid #000;
}
.ll-lang_select .select-menu {
  background: #f9f9f9;
  box-shadow: 0 0 4px 0 #000;
  border: 1px solid #000;
}
.ll-edit-profile .profile_title {
  font-size: 16px;
  font-weight: 800;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 10px;
}
@media screen and (max-width: 1020px) {
  .ll-edit-profile .profile_gallery_wrap,
  .ll-my-profile-popup_body .profile_gallery_wrap,
  .ll-popup_body__profile .profile_gallery_wrap {
    min-height: 244px;
    height: 244px;
    width: 100%;
  }

  main.ll_page_people {
    padding-top: 20px;
  }

  .ll_page_people .container-with-filter .btn_filter {
    margin-top: 0;
  }

  .ll_page_people .container-with-filter {
    flex-direction: row;
    align-items: center;
  }

  .ll_page_people .add-text {
    display: none;
  }

  .ll-sub_btn_sale-test {
    max-width: 100% !important;
    margin-top: -35px !important;
  }

  .ll_page_people .wrapper_cards {
    margin-left: 0px;
  }

  .ll-card {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    max-width: 450px;
    min-width: 100%;
  }

  .ll-step_form .mobile_step_title {
    font-size: 20px !important;
  }
  .ll-step_form .mobile_step_text {
    font-size: 14px !important;
  }
  .ll_step_skip {
    text-align: center;
    width: 100%;
    margin-top: 50px;
  }
  .ll-my-profile-popup_body .popup_head {
    flex-direction: column;
  }
  .ll_premium_indicator span {
    margin-left: 0 !important;
  }

  .ll-my-profile-popup_body .popup_head .btn_close {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .notifications__list .ll_dialog.new {
    padding: 0px;
    width: 90%;
    margin-left: 20px;
    margin-right: 20px;
  }

  .ll-gallery .gallery_photo__actions {
    flex-direction: column-reverse !important;
  }
}

.ll-main-form .location_select-menu {
  position: absolute !important;
  top: 50px !important;
}
.ll-main-form .form_input:hover {
  background: #f9f9f9;
}
/*.ll-main-form .select-menu-single {*/
/*  width: 100px;*/
/*}*/
.ll-item_select .form_input div {
  min-width: 40px;
}
.ll-card .card_img {
  background-color: #e0e0e0 !important;
}
.ll-messages-area__container .chat_ask li {
  background: #f9f9f9;
  color: #000;
  border-radius: 4px;
}
.ll-notifications .new_notification {
  border-radius: 4px;
  width: 13px;
  height: 13px;
}
.ll-dialog {
  display: flex;
  border-radius: 4px;
  padding: 0;
  position: relative;
  margin-bottom: 6px;
  cursor: pointer;
}
.ll-dialog.new {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #dedede;
}
.ll-dialog.new .new_message {
  border-radius: 4px;
}
.ll-dialog.current {
  background: #dbe6ee;
}
.ll-dialog.new .dialog_content {
  border: none;
}
.ll-dialog .dialog_content {
  border: none;
}
.ll-dialog .dialog_location {
  color: #000000;
}
.ll-dialog .dialog_title {
  color: #000000;
}
.ll-dialog .dialog_preview {
  color: #000000;
}

.ll-dialog.new .dialog_location {
  color: #000000;
}
.ll-dialog.new .dialog_title {
  color: #000000;
}
.ll-dialog.new .dialog_preview {
  color: #000000;
}
.ll-popup_body__profile .messages-form-input {
  background: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #000;
}
.ll-popup_body__profile .profile_waiting__text {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}
.ll-popup_body__profile .btn_link {
  color: #999999;
  font-size: 14px;
}
.ll-main-form__bottom .select-menu-single {
  bottom: auto !important;
}
.ll-main-form__bottom .dropdown_day {
  max-width: 100px;
}

.ll-popup-purchase {
  background: #f2f2f2 !important;
  border-radius: 4px;
}
.ll-popup-purchase .purchase-coins {
  background: linear-gradient(180deg, #f26265 0%, #b73d40 100%) !important;
  border-radius: 4px;
  height: 155px;
  position: relative;
  margin-bottom: 40px;
}
.ll-popup-purchase .purchase-coins:hover {
  background: linear-gradient(180deg, #b73d40 0%, #f26265 100%) !important;
}
.ll-popup-purchase .purchase-coins__all_price {
  position: absolute;
  bottom: -23px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #999;
}
.ll-popup-purchase .purchase-coins__coins {
  color: #fff !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 30px;
  font-size: 30px;
  align-items: center;
}
.ll-popup-purchase .purchase-coins__sum_per_coins {
  margin-top: 20px;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
}
.ll-popup-purchase .purchase-coins__coins span {
  color: #fff !important;
  font-size: 14px;
  line-height: 27px;
}
.ll-popup-purchase .purchase-coins__discount {
  border-radius: 4px;
}
.ll-popup-purchase .purchase-coins__sum {
  color: #fff !important;
}
.ll-popup-purchase .popup-purchase__agree a {
  color: #999999;
}
.ll-popup-purchase .popup-purchase__agree a:hover {
  text-decoration: underline;
}
.ll-popup-purchase .btn_close {
  fill: #fff !important;
}
.ll-popup-purchase .btn_close:hover {
  fill: #fff !important;
}
.ll-popup-purchase .purchase-sub__container {
  background: #f7b500;
  color: #000000;
}
.ll-popup-purchase .purchase-sub__container:hover {
  background: #f7b500;
}
.ll-popup-purchase .purchase-sub__container_trial {
  background: #fff;
  border: none;
}
.ll-popup-purchase .purchase-sub__container_trial:hover {
  background: #fff;
  border: none;
  color: #000000;
}
.ll-popup-purchase .purchase-sub__benefit {
  color: #000000;
  border: 1px solid #000;
}
.ll-popup-purchase .discount-text {
  color: #fff !important;
}
.ll-popup-purchase .purchase-sub__benefit_trial {
  background: #b620e0;
  color: #fff;
}
.ll-search-settings {
  border-radius: 4px;
}
.ll-search-settings .popup_head {
  background: #f2f2f2 !important;
}
.ll-search-settings .popup_footer {
  background: #f2f2f2 !important;
}
.ll-search-settings .popup_content {
  background: #fff;
}
.ll-search-settings .form_input {
  background: #f9f9f9 !important;
  border: 1px solid #000000;
  border-radius: 4px;
}
.ll-search-settings .form_input svg path {
  stroke: #000000;
}
.ll-search-settings .btn_filter:hover {
  color: #999;
}
.ll_page_people .wrapper {
  justify-content: space-between;
}
.ll-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ll_page_people .btn_filter {
  font-weight: 400;
  color: #999;
}
.ll-edit-profile .form_label {
  font-size: 14px;
  color: #6f7579;
}
.ll-gallery .gallery_photo__actions {
  background: none;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}
.ll-gallery .gallery_photo__actions .btn {
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 18px;
  font-weight: 500;
  border-radius: 0px;
}
.ll-gallery .gallery_photo__actions .btn .btn_icon {
  width: 33px;
  height: 33px;
  margin-right: 10px;
}
.ll-gallery .gallery_photo__actions .btn:first-child {
  border-radius: 0 4px 4px 0;
}
.ll-gallery .gallery_photo__actions .btn:last-child {
  border-radius: 4px 0 0 4px;
}

.ll-gallery .btn_round {
  border-radius: 4px;
  margin-right: 10px;
}
.ll-gallery .btn_like {
  background: linear-gradient(180deg, #f26265 0%, #b73d40 100%);
}
.ll-gallery .btn_like_true {
  background: linear-gradient(180deg, #f26265 0%, #b73d40 100%);
}
.ll-gallery .btn_chat {
  border-radius: 4px !important;
}

.ll-nav-menu__item .count-new-messages {
  top: 2px;
  right: 3px;
  left: auto;
}

.ll-popup_body__chat {
  border-radius: 4px;
}
.ll-confirm .confirm__container {
  border-radius: 4px;
}
.ll-confirm .confirm__title {
  color: #000;
}
.ll-confirm .confirm__text {
  color: #000;
}
.ll-confirm .delete_btn {
  background: linear-gradient(180deg, #f26265 0%, #b73d40 100%) !important;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
}
.ll-confirm .btn_form:hover {
  background: linear-gradient(180deg, #b73d40 0%, #f26265 100%) !important;
}

.ll-step_form {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 4px;
}
.ll-step_form .btn_form {
  background: linear-gradient(180deg, #f26265 0%, #b73d40 100%);
  height: 40px;
  width: 260px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 400;
}
.ll-step_form .btn_form:hover {
  background: linear-gradient(180deg, #b73d40 0%, #f26265 100%);
}
.ll-step_form .btn_form:disabled {
  background: #ce8284 !important;
  color: #fff;
}
.ll-popup_body__profile .btn_primary:disabled {
  background: #c56b6d;
  color: #fff;
}
.ll-step_form .btn_photo {
  background: #f8e3e3;
  border-radius: 4px;
  border: 1px solid #e75b5e;
  margin: 65px auto 50px;
}
.ll-step_form .upload-photo {
  margin: 65px auto 50px;
}
.ll-step_form .btn_link {
  color: #999;
  font-size: 14px;
}

.ll-step_form .step_skip {
  margin-bottom: 10px;
}
.ll-step_form .btn_link:hover {
  color: #999;
}

.ll-step_form .activities_item {
  background: #f8e3e3;
  border: 1px solid #eb494d;
  border-radius: 4px;
  color: #eb494d;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}
.ll-step_form .activities_item:hover {
  background: #eb494d;
  border: 1px solid #eb494d;
  color: white;
}
.ll-step_form .activities_item.active {
  background: #eb494d;
  border: 1px solid #eb494d;
  color: #fff;
}
.ll-step_form .form_label {
  font-size: 14px;
  font-weight: 400;
}

.ll-popup-purchase {
  background: #ffffff !important;
  border-radius: 4px;
}

.ll-popup-purchase .purchase-coins__coins {
  color: #000000;
}
.ll-popup-purchase .purchase-coins__sum {
  color: #000000;
}
.ll-popup-purchase .popup-purchase__agree a {
  color: #999;
}
.ll-popup-purchase .popup-purchase__agree a:hover {
  text-decoration: underline;
}
.ll-popup-purchase .btn_close {
  fill: #fff !important;
}
.ll-popup-purchase .btn_close:hover {
  fill: #fff !important;
}
.ll-popup-purchase .purchase-sub__container {
  background: linear-gradient(180deg, #f26265 0%, #b73d40 100%);
  color: #fff;
}
.ll-popup-purchase .purchase-sub__container:hover {
  background: linear-gradient(180deg, #b73d40 0%, #f26265 100%);
}
.ll-popup-purchase .purchase-sub__container_trial {
  background: #fff;
  border: 2px solid #000;
  color: #000;
}
.ll-popup-purchase .purchase-sub__container_trial:hover {
  background: #fff;
  border: 2px solid #000;
  color: #000000;
}
.ll-popup-purchase .purchase-sub__benefit {
  color: #000000;
  border: 1px solid #000;
}
.ll-popup-purchase .discount-text {
  color: #000 !important;
}
.ll-popup-purchase .purchase-sub__benefit_trial {
  background: #f26265;
  color: #fff;
}
.ll-gallery .slick-next,
.ll-gallery .slick-prev {
  height: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.8) !important;
  border-radius: 0px;
}
.ll-gallery .slick-next {
  right: 0;
}
.ll-gallery .slick-next:before,
.ll-gallery [dir='rtl'] .slick-prev:before {
  content: url(/img/ll-next-arrow.svg);
}
.ll-gallery .slick-prev:before,
.ll-gallery [dir='rtl'] .slick-next:before {
  content: url(/img/ll-next-arrow.svg);
  transform: rotate(180deg);
}

.ll-gallery .slick-prev {
  left: 0;
}

.ll-gallery .slick-prev::before,
.ll-gallery [dir='rtl'] .slick-next::before {
  left: calc(50% - 10px);
  top: calc(50% - 13px);
}
.ll-gallery .carousel-arrow-next,
.ll-gallery .carousel-arrow-prev {
  height: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.8) !important;
  border-radius: 0px;
}
.ll-gallery .carousel-arrow-next {
  right: 0;
}
.ll-gallery .carousel-arrow-next:before,
.ll-gallery [dir='rtl'] .carousel-arrow-prev:before {
  content: url(/img/ll-next-arrow.svg);
}
.ll-gallery .carousel-arrow-prev:before,
.ll-gallery [dir='rtl'] .carousel-arrow-next:before {
  content: url(/img/ll-next-arrow.svg);
  transform: rotate(180deg);
}

.ll-gallery .carousel-arrow-prev {
  left: 0;
}

.ll-gallery .carousel-arrow-prev::before,
.ll-gallery [dir='rtl'] .carousel-arrow-next::before {
  left: calc(50% - 10px);
  top: calc(50% - 13px);
}

.ll-sub_btn_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.ll-sub_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, #f26265 0%, #b73d40 100%);
  border-radius: 4px;
  padding: 10px 30px;
  max-width: 425px;
  width: 100%;
  height: 72px;
  cursor: pointer;
  position: relative;
}
.ll-sub_btn_month {
  color: white;
  font-size: 20px;
  font-weight: 700;
}
.ll-sub_btn_price {
  color: white;
  font-size: 20px;
  font-weight: 700;
}
.ll-sub_btn:hover {
  background: linear-gradient(180deg, #b73d40 0%, #f26265 100%);
}
.ll-sub_btn_secondary {
  font-size: 14px;
  color: #999;
  margin-top: 10px;
}
.ll-sub_btn_sale {
  background: #ffffff;
  border: 2px solid #bb4043;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 0;
  width: 100%;
  max-width: 175px;
  height: 40px;
  font-size: 20px;
  color: #bb4043;
  transform: translateX(-50%);
  margin-top: -20px;
}
.ll_premium_indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #eb494d;
  font-size: 16px;
  font-weight: bold;
}
.ll_premium_indicator span {
  margin-left: 4px;
}

.ll-popup-sign-in {
  background: #ffffff;
  border-radius: 4px;
}

.ll-popup-sign-in .popup_close svg path {
  fill: #000;
}
.ll-popup-sign-in .btn_form {
  background: var(--primary-color);
  color: #ffffff;
}
.ll-popup-sign-in .btn_form:hover {
  background: var(--primary-color);
  color: #ffffff;
}
.ll-popup-sign-in .btn_form:disabled {
  background: #f8f8f8;
  color: var(--light-color-text);
  font-weight: 700;
}
.ll-popup-sign-in .btn_link {
  color: #fff;
}
.ll-main-form__error_text {
  margin-top: -30px;
  color: #eb494d;
  font-weight: bold;
  margin-bottom: 20px;
}
