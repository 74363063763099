.chat_photo {
  width: 100px;
  height: 100px;
  border: 1px solid #fff;
  border-radius: 50%;

  &.no_photo {
    background: #c3d2db url('/img/no-photo.svg') no-repeat center bottom;
    background-size: 95px;
    border-radius: 50%;
  }
}
.ll-messages-area__head .chat_photo {
  width: 118px;
  height: 118px;
  border: 1px solid #fff;
  border-radius: 4px;

  &.no_photo {
    background: #c3d2db url('/img/no-photo.svg') no-repeat center bottom;
    background-size: 95px;
    border-radius: 4px;
  }
}
