/* Font connection */
/*@font-face {*/
/*    font-family: 'SF Pro Text';*/
/*    src: url('/fonts/subset-SFProText-Heavy.eot');*/
/*    src: url('/fonts/subset-SFProText-Heavy.eot?#iefix') format('embedded-opentype'),*/
/*    url('/fonts/subset-SFProText-Heavy.woff2') format('woff2'),*/
/*    url('/fonts/subset-SFProText-Heavy.woff') format('woff'),*/
/*    url('/fonts/subset-SFProText-Heavy.ttf') format('truetype'),*/
/*    url('/fonts/subset-SFProText-Heavy.svg#SFProText-Heavy') format('svg');*/
/*    font-weight: 900;*/
/*    font-style: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: 'SF Pro Text';*/
/*    src: url('/fonts/subset-SFProText-Regular.eot');*/
/*    src: url('/fonts/subset-SFProText-Regular.eot?#iefix') format('embedded-opentype'),*/
/*    url('/fonts/subset-SFProText-Regular.woff2') format('woff2'),*/
/*    url('/fonts/subset-SFProText-Regular.woff') format('woff'),*/
/*    url('/fonts/subset-SFProText-Regular.ttf') format('truetype'),*/
/*    url('/fonts/subset-SFProText-Regular.svg#SFProText-Regular') format('svg');*/
/*    font-weight: normal;*/
/*    font-style: normal;*/
/*}*/
/* /Font connection */

.agreement a {
    display: inline-block;
}

h2 {
    line-height: 1.2em;
}

.container.lk-container {
    padding-top: 75px;
    font-size: 18px;
}

.lk-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 990px;
    margin: 0 auto;
}

.agreement {
    padding-bottom: 50px;
    padding-top: 10px;
}

.agreement h1 {
    font-size: 20px;
    margin-bottom: 20px;
}

/*** main ***/
main .lk-wrapper {
    align-items: center;
    position: relative;
}

main .lk-wrapper:last-child:after {
    content: none;
}

.lk-wrapper_start {
    justify-content: flex-start;
}

.lk-wrapper_end {
    justify-content: flex-end;
}

.lk-wrapper_start:after, .lk-wrapper_end:after {
    display: block;
    width: 230px;
    height: 214px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -110px;
}

.lk-wrapper_start:after {
    content: url("/img/line1.svg");
}

.lk-wrapper_end:after {
    content: url("/img/line2.svg");
}

.lk-card {
    border-radius: 17px;
    background: #d8e5ee;
    padding: 30px;
}

.lk-card__title {
    font-weight: 900;
    font-size: 24px;
    margin-bottom: 20px;
}

.lk-wrapper_start .lk-card {
    text-align: left;
    margin-left: 20px;
    margin-right: 10px;
}

.lk-wrapper_end .lk-card {
    text-align: right;
    margin-right: 20px;
    margin-left: 10px;
}

/*** Media Queries ***/

@media (max-width: 767px) {
    .lk-wrapper {
        flex-direction: column;
    }

    .lk-wrapper.lk-wrapper_end {
        flex-direction: column-reverse;
    }

    .lk-wrapper_start .lk-card {
        margin-left: 10px;
    }

    .lk-wrapper_start:after, .lk-wrapper_end:after {
        content: none;
        display: none;
    }

    .lk-wrapper svg {
        width: 100%;
    }

    .lk-wrapper_end .lk-card {
        margin-right: 10px;
    }

    .lk-card {
        padding: 20px 20px 30px;
    }

    .lk-card__title {
        margin-bottom: 10px;
    }
}
